import ServiceWorkshop from "../../service/workshop/ServiceWorkshop.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  workshopVehicleInfo: {},
  workshopVehiclesList: [],
  workshopVehiclesCount: null,

  customerWorkshopVehiclesList: [],
  customerWorkshopVehiclesCount: null,

  jobKitCategoriesList: [],
  jobKitCategoriesCount: null,

  jobKitsList: [],
  jobKitsCount: null,

  jobsList: [],
  jobsCount: null,

  customerJobsList: [],
  customerJobsCount: null,

  jobServiceReportTaskId: null,

  workshopJobCardTaskId: null,

  jobDocumentsList: [],
  jobDocumentsCount: null,

  generalServicesList: [],
  generalServicesCount: null,

  wofConflictFormGenerateTaskId: null,

  recommendationCategoryList: [],
  recommendationCategoryCount: null,

  recommendationList: [],
  recommendationCount: null,
};

export const mutations = {
  SET_WORKSHOP_VEHICLE_INFO(state, data) {
    state.workshopVehicleInfo = data;
  },

  SET_WORKSHOP_VEHICLES_LIST(state, data) {
    state.workshopVehiclesList = data;
  },

  SET_WORKSHOP_VEHICLES_COUNT(state, count) {
    state.workshopVehiclesCount = count;
  },

  ADD_WORKSHOP_VEHICLE(state, workshopVehicle) {
    state.workshopVehiclesList.unshift(workshopVehicle);
  },

  UPDATE_WORKSHOP_VEHICLE(state, workshopVehicle) {
    state.workshopVehiclesList = state.workshopVehiclesList.map((element) => {
      if (element.id === workshopVehicle.id) {
        element = JSON.parse(JSON.stringify(workshopVehicle));
      }
      return element;
    });
  },

  // customer
  SET_CUSTOMER_WORKSHOP_VEHICLES_LIST(state, data) {
    state.customerWorkshopVehiclesList = data;
  },

  SET_CUSTOMER_WORKSHOP_VEHICLES_COUNT(state, count) {
    state.customerWorkshopVehiclesCount = count;
  },

  CLEAR_CUSTOMER_WORKSHOP_VEHICLES_DATA(state) {
    state.customerWorkshopVehiclesList = [];
    state.customerWorkshopVehiclesCount = null;
  },

  SET_CUSTOMER_WORKSHOP_JOBS_LIST(state, data) {
    state.customerJobsList = data;
  },

  SET_CUSTOMER_WORKSHOP_JOBS_COUNT(state, count) {
    state.customerJobsCount = count;
  },

  CLEAR_CUSTOMER_WORKSHOP_JOBS_DATA(state) {
    state.customerJobsList = [];
    state.customerJobsCount = null;
  },

  // job kit category
  SET_JOB_KIT_CATEGORIES_LIST(state, data) {
    state.jobKitCategoriesList = data;
  },

  SET_JOB_KIT_CATEGORIES_COUNT(state, count) {
    state.jobKitCategoriesCount = count;
  },

  ADD_JOB_KIT_CATEGORY(state, jobKitCategory) {
    state.jobKitCategoriesList.unshift(jobKitCategory);
  },

  UPDATE_JOB_KIT_CATEGORY(state, jobKitCategory) {
    state.jobKitCategoriesList = state.jobKitCategoriesList.map((element) => {
      if (element.id === jobKitCategory.id) {
        element = JSON.parse(JSON.stringify(jobKitCategory));
      }
      return element;
    });
  },

  DELETE_JOB_KIT_CATEGORY(state, objId) {
    state.jobKitCategoriesList = state.jobKitCategoriesList.filter(
      (element) => {
        if (element.id !== objId) {
          return element;
        }
      }
    );
  },

  // job kit
  SET_JOB_KITS_LIST(state, data) {
    state.jobKitsList = data;
  },

  SET_JOB_KITS_COUNT(state, count) {
    state.jobKitsCount = count;
  },

  ADD_JOB_KIT(state, jobKit) {
    state.jobKitsList.unshift(jobKit);
  },

  UPDATE_JOB_KIT(state, jobKit) {
    state.jobKitsList = state.jobKitsList.map((element) => {
      if (element.id === jobKit.id) {
        element = JSON.parse(JSON.stringify(jobKit));
      }
      return element;
    });
  },

  DELETE_JOB_KIT(state, jobKitUUID) {
    state.jobKitsList = state.jobKitsList.filter((element) => {
      if (element.jobkit_uuid !== jobKitUUID) {
        return element;
      }
    });
  },

  // job
  SET_WORKSHOP_JOBS_LIST(state, data) {
    state.jobsList = data;
  },

  SET_WORKSHOP_JOBS_COUNT(state, count) {
    state.jobsCount = count;
  },

  ADD_WORKSHOP_JOB(state, job) {
    state.jobsList.unshift(job);
  },

  UPDATE_WORKSHOP_JOB(state, job) {
    state.jobsList = state.jobsList.map((element) => {
      if (element.id === job.id) {
        element = JSON.parse(JSON.stringify(job));
      }
      return element;
    });
  },

  DELETE_WORKSHOP_JOB(state, jobUUID) {
    state.jobsList = state.jobsList.filter((element) => {
      if (element.job_uuid !== jobUUID) {
        return element;
      }
    });
  },

  SAVE_SERVICE_REPORT_TASK_ID(state, taskId) {
    state.jobServiceReportTaskId = taskId;
  },

  CLEAR_SERVICE_REPORT_TASK_ID(state) {
    state.jobServiceReportTaskId = null;
  },

  SAVE_WORKSHOP_JOB_CARD_TASK_ID(state, taskId) {
    state.workshopJobCardTaskId = taskId;
  },

  CLEAR_WORKSHOP_JOB_CARD_TASK_ID(state) {
    state.workshopJobCardTaskId = null;
  },

  // job document
  SET_WORKSHOP_JOB_DOCUMENTS_LIST(state, data) {
    state.jobDocumentsList = data;
  },

  SET_WORKSHOP_JOB_DOCUMENTS_COUNT(state, count) {
    state.jobDocumentsCount = count;
  },

  ADD_WORKSHOP_JOB_DOCUMENT(state, jobDocument) {
    state.jobDocumentsList.unshift(jobDocument);
  },

  UPDATE_WORKSHOP_JOB_DOCUMENT(state, jobDocument) {
    state.jobDocumentsList = state.jobDocumentsList.map((element) => {
      if (element.id === jobDocument.id) {
        element = JSON.parse(JSON.stringify(jobDocument));
      }
      return element;
    });
  },

  DELETE_WORKSHOP_JOB_DOCUMENT(state, jobDocumentUUID) {
    state.jobDocumentsList = state.jobDocumentsList.filter((element) => {
      if (element.document_uuid !== jobDocumentUUID) {
        return element;
      }
    });
  },

  // general service
  SET_WORKSHOP_GENERAL_SERVICES_LIST(state, data) {
    state.generalServicesList = data;
  },

  SET_WORKSHOP_GENERAL_SERVICES_COUNT(state, count) {
    state.generalServicesCount = count;
  },

  ADD_WORKSHOP_GENERAL_SERVICE(state, generalService) {
    state.generalServicesList.unshift(generalService);
  },

  UPDATE_WORKSHOP_GENERAL_SERVICE(state, generalService) {
    state.generalServicesList = state.generalServicesList.map((element) => {
      if (element.id === generalService.id) {
        element = JSON.parse(JSON.stringify(generalService));
      }
      return element;
    });
  },

  DELETE_WORKSHOP_GENERAL_SERVICE(state, serviceUUID) {
    state.generalServicesList = state.generalServicesList.filter((element) => {
      if (element.service_uuid !== serviceUUID) {
        return element;
      }
    });
  },

  // wof job
  SAVE_WOF_CONFLICT_FORM_GENERATE_TASK_ID(state, taskId) {
    state.wofConflictFormGenerateTaskId = taskId;
  },

  CLEAR_WOF_CONFLICT_FORM_GENERATE_TASK_ID(state) {
    state.wofConflictFormGenerateTaskId = null;
  },

  // recommendation categories
  SET_RECOMMENDATION_CATEGORY_LIST(state, data) {
    state.recommendationCategoryList = data;
  },

  SET_RECOMMENDATION_CATEGORY_COUNT(state, count) {
    state.recommendationCategoryCount = count;
  },

  ADD_RECOMMENDATION_CATEGORY(state, category) {
    state.recommendationCategoryList.unshift(category);
  },

  UPDATE_RECOMMENDATION_CATEGORY(state, category) {
    state.recommendationCategoryList = state.recommendationCategoryList.map(
      (element) => {
        if (element.id === category.id) {
          element = JSON.parse(JSON.stringify(category));
        }
        return element;
      }
    );
  },

  DELETE_RECOMMENDATION_CATEGORY(state, objId) {
    state.recommendationCategoryList = state.recommendationCategoryList.filter(
      (element) => {
        if (element.id != objId) {
          return element;
        }
      }
    );
  },

  ADD_RECOMMENDATION(state, recommendation) {
    state.recommendationList.unshift(recommendation);
  },

  SET_RECOMMENDATION_LIST(state, data) {
    state.recommendationList = data;
  },

  SET_RECOMMENDATION_COUNT(state, count) {
    state.recommendationCount = count;
  },

  UPDATE_RECOMMENDATION(state, recommendation) {
    state.recommendationList = state.recommendationList.map((element) => {
      if (element.uuid === recommendation.uuid) {
        element = JSON.parse(JSON.stringify(recommendation));
      }
      return element;
    });
  },

  DELETE_RECOMMENDATION(state, uuid) {
    state.recommendationList = state.recommendationList.filter((element) => {
      if (element.uuid != uuid) {
        return element;
      }
    });
  },
};

export const actions = {
  getWorkshopVehiclesList(
    { commit },
    {
      perPage,
      page,
      searchQuery,
      fuelTypes,
      bodyStyles,
      transmissions,
      driveWheels,
      statuses,
      owner,
      universalSearch,
    }
  ) {
    return ServiceWorkshop.getWorkshopVehiclesList(
      perPage,
      page,
      searchQuery,
      fuelTypes,
      bodyStyles,
      transmissions,
      driveWheels,
      statuses,
      owner
    )
      .then((resp) => {
        if (universalSearch !== true) {
          commit("SET_WORKSHOP_VEHICLES_LIST", resp.data.results);
          commit("SET_WORKSHOP_VEHICLES_COUNT", resp.data.count);
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopVehiclesListWithoutPagination({}, searchQuery) {
    return ServiceWorkshop.getWorkshopVehiclesListWithoutPagination(searchQuery)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCustomerWorkshopVehiclesList(
    { commit },
    { customerUUID, perPage, page, searchQuery }
  ) {
    return ServiceWorkshop.getCustomerWorkshopVehiclesList(
      customerUUID,
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_CUSTOMER_WORKSHOP_VEHICLES_LIST", resp.data.results);
        commit("SET_CUSTOMER_WORKSHOP_VEHICLES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addWorkshopVehicle({ commit }, formData) {
    return ServiceWorkshop.addWorkshopVehicle(formData)
      .then((resp) => {
        commit("ADD_WORKSHOP_VEHICLE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Vehicle added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.registration_plate) {
            message =
              "Registration Plate: " + err.response.data.registration_plate;
          } else if (err.response.data.vin) {
            message = "VIN: " + err.response.data.vin;
          } else if (err.response.data.chassis) {
            message = "Chassis: " + err.response.data.chassis;
          } else if (err.response.data.year) {
            message = "Year: " + err.response.data.year;
          } else if (err.response.data.make) {
            message = "Make: " + err.response.data.make;
          } else if (err.response.data.model) {
            message = "Model: " + err.response.data.model;
          } else if (err.response.data.submodel) {
            message = "Submodel: " + err.response.data.submodel;
          } else if (err.response.data.kilometers) {
            message = "Kilometers: " + err.response.data.kilometers;
          } else if (err.response.data.transmission) {
            message = "Transmission: " + err.response.data.transmission;
          } else if (err.response.data.fuel_type) {
            message = "Fuel Type: " + err.response.data.fuel_type;
          } else if (err.response.data.cc) {
            message = "CC Rating: " + err.response.data.cc;
          } else if (err.response.data.drive_wheel) {
            message = "Drive Wheel: " + err.response.data.drive_wheel;
          } else if (err.response.data.body_style) {
            message = "Body Style: " + err.response.data.body_style;
          } else if (err.response.data.exterior_color) {
            message = "Exterior Color: " + err.response.data.exterior_color;
          } else if (err.response.data.interior_color) {
            message = "Interior Color: " + err.response.data.interior_color;
          } else if (err.response.data.interior_type) {
            message = "Interior Type: " + err.response.data.interior_type;
          } else if (err.response.data.oil_grade) {
            message = "Oil Grade: " + err.response.data.oil_grade;
          } else if (err.response.data.oil_quantity) {
            message = "Oil Quantity: " + err.response.data.oil_quantity;
          } else if (err.response.data.oil_filter_type) {
            message = "Oil Filter Type: " + err.response.data.oil_filter_type;
          } else if (err.response.data.oil_filter_torque_spec) {
            message =
              "Oil Filter Torque Spec: " +
              err.response.data.oil_filter_torque_spec;
          } else if (err.response.data.drain_plug_torque_spec) {
            message =
              "Drain Plug Torque Spec: " +
              err.response.data.drain_plug_torque_spec;
          } else if (err.response.data.note_condition) {
            message = "Condition Note: " + err.response.data.note_condition;
          } else if (err.response.data.note_tyres) {
            message = "Tyres Note: " + err.response.data.note_tyres;
          } else if (err.response.data.front_tyre) {
            message = "Front Tyre: " + err.response.data.front_tyre;
          } else if (err.response.data.rear_tyre) {
            message = "Rear Tyre: " + err.response.data.rear_tyre;
          } else if (err.response.data.doors) {
            message = "Doors: " + err.response.data.doors;
          } else if (err.response.data.seats) {
            message = "Seats: " + err.response.data.seats;
          } else if (err.response.data.airbags) {
            message = "Airbags: " + err.response.data.airbags;
          } else if (err.response.data.registration_expiry_date) {
            message =
              "Registration Expiry Date: " +
              err.response.data.registration_expiry_date;
          } else if (err.response.data.is_ruc_applies) {
            message = "RUC Applies: " + err.response.data.is_ruc_applies;
          } else if (err.response.data.ruc_paid_to) {
            message = "RUC Paid To: " + err.response.data.ruc_paid_to;
          } else if (err.response.data.service_due) {
            message = "Service Due: " + err.response.data.service_due;
          } else if (err.response.data.service_due_date) {
            message = "Service Due Date: " + err.response.data.service_due_date;
          } else if (err.response.data.vehiclephoto_set) {
            for (const obj of err.response.data.vehiclephoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveWorkshopVehicle({}, vehicleUUID) {
    return ServiceWorkshop.retrieveWorkshopVehicle(vehicleUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateWorkshopVehicle({ commit }, { vehicleUUID, formData }) {
    return ServiceWorkshop.updateWorkshopVehicle(vehicleUUID, formData)
      .then((resp) => {
        commit("UPDATE_WORKSHOP_VEHICLE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Vehicle updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.registration_plate) {
            message =
              "Registration Plate: " + err.response.data.registration_plate;
          } else if (err.response.data.vin) {
            message = "VIN: " + err.response.data.vin;
          } else if (err.response.data.chassis) {
            message = "Chassis: " + err.response.data.chassis;
          } else if (err.response.data.year) {
            message = "Year: " + err.response.data.year;
          } else if (err.response.data.make) {
            message = "Make: " + err.response.data.make;
          } else if (err.response.data.model) {
            message = "Model: " + err.response.data.model;
          } else if (err.response.data.submodel) {
            message = "Submodel: " + err.response.data.submodel;
          } else if (err.response.data.kilometers) {
            message = "Kilometers: " + err.response.data.kilometers;
          } else if (err.response.data.transmission) {
            message = "Transmission: " + err.response.data.transmission;
          } else if (err.response.data.fuel_type) {
            message = "Fuel Type: " + err.response.data.fuel_type;
          } else if (err.response.data.cc) {
            message = "CC Rating: " + err.response.data.cc;
          } else if (err.response.data.drive_wheel) {
            message = "Drive Wheel: " + err.response.data.drive_wheel;
          } else if (err.response.data.body_style) {
            message = "Body Style: " + err.response.data.body_style;
          } else if (err.response.data.exterior_color) {
            message = "Exterior Color: " + err.response.data.exterior_color;
          } else if (err.response.data.interior_color) {
            message = "Interior Color: " + err.response.data.interior_color;
          } else if (err.response.data.interior_type) {
            message = "Interior Type: " + err.response.data.interior_type;
          } else if (err.response.data.oil_grade) {
            message = "Oil Grade: " + err.response.data.oil_grade;
          } else if (err.response.data.oil_quantity) {
            message = "Oil Quantity: " + err.response.data.oil_quantity;
          } else if (err.response.data.oil_filter_type) {
            message = "Oil Filter Type: " + err.response.data.oil_filter_type;
          } else if (err.response.data.oil_filter_torque_spec) {
            message =
              "Oil Filter Torque Spec: " +
              err.response.data.oil_filter_torque_spec;
          } else if (err.response.data.drain_plug_torque_spec) {
            message =
              "Drain Plug Torque Spec: " +
              err.response.data.drain_plug_torque_spec;
          } else if (err.response.data.note_condition) {
            message = "Condition Note: " + err.response.data.note_condition;
          } else if (err.response.data.note_tyres) {
            message = "Tyres Note: " + err.response.data.note_tyres;
          } else if (err.response.data.front_tyre) {
            message = "Front Tyre: " + err.response.data.front_tyre;
          } else if (err.response.data.rear_tyre) {
            message = "Rear Tyre: " + err.response.data.rear_tyre;
          } else if (err.response.data.doors) {
            message = "Doors: " + err.response.data.doors;
          } else if (err.response.data.seats) {
            message = "Seats: " + err.response.data.seats;
          } else if (err.response.data.airbags) {
            message = "Airbags: " + err.response.data.airbags;
          } else if (err.response.data.registration_expiry_date) {
            message =
              "Registration Expiry Date: " +
              err.response.data.registration_expiry_date;
          } else if (err.response.data.is_ruc_applies) {
            message = "RUC Applies: " + err.response.data.is_ruc_applies;
          } else if (err.response.data.ruc_paid_to) {
            message = "RUC Paid To: " + err.response.data.ruc_paid_to;
          } else if (err.response.data.service_due) {
            message = "Service Due: " + err.response.data.service_due;
          } else if (err.response.data.service_due_date) {
            message = "Service Due Date: " + err.response.data.service_due_date;
          } else if (err.response.data.vehiclephoto_set) {
            for (const obj of err.response.data.vehiclephoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getWorkshopVehicleFormData({}) {
    return ServiceWorkshop.getWorkshopVehicleFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopVehicleDataFromCarjam({}, plateNo) {
    return ServiceWorkshop.getWorkshopVehicleDataFromCarjam(plateNo)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // job kit category
  getJobKitCategoriesList(
    { commit },
    { perPage, page, searchQuery, department }
  ) {
    return ServiceWorkshop.getJobKitCategoriesList(
      perPage,
      page,
      searchQuery,
      department
    )
      .then((resp) => {
        commit("SET_JOB_KIT_CATEGORIES_LIST", resp.data.results);
        commit("SET_JOB_KIT_CATEGORIES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobKitCategoriesListWithoutPagination() {
    return ServiceWorkshop.getJobKitCategoriesListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobKitCategoryFormData() {
    return ServiceWorkshop.getJobKitCategoryFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addJobKitCategory({ commit }, formData) {
    return ServiceWorkshop.addJobKitCategory(formData)
      .then((resp) => {
        commit("ADD_JOB_KIT_CATEGORY", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Kit Category added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveJobKitCategory({}, objId) {
    return ServiceWorkshop.retrieveJobKitCategory(objId)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateJobKitCategory({ commit }, { objId, formData }) {
    return ServiceWorkshop.updateJobKitCategory(objId, formData)
      .then((resp) => {
        commit("UPDATE_JOB_KIT_CATEGORY", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Kit Category updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteJobKitCategory({ commit }, objId) {
    return ServiceWorkshop.deleteJobKitCategory(objId)
      .then((resp) => {
        commit("DELETE_JOB_KIT_CATEGORY", objId);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Job Kit Category deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // job kit
  getJobKitsList(
    { commit },
    { perPage, page, searchQuery, department, categories }
  ) {
    return ServiceWorkshop.getJobKitsList(
      perPage,
      page,
      searchQuery,
      department,
      categories
    )
      .then((resp) => {
        commit("SET_JOB_KITS_LIST", resp.data.results);
        commit("SET_JOB_KITS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobKitsListWithoutPagination() {
    return ServiceWorkshop.getJobKitsListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobKitFormData() {
    return ServiceWorkshop.getJobKitFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addJobKit({ commit }, formData) {
    return ServiceWorkshop.addJobKit(formData)
      .then((resp) => {
        commit("ADD_JOB_KIT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Kit added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.category) {
            message = "Category: " + err.response.data.category;
          } else if (err.response.data.title) {
            message = "Title: " + err.response.data.title;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.parts) {
            message = "Parts: " + err.response.data.parts;
          } else if (err.response.data.labours) {
            message = "Labours: " + err.response.data.labours;
          } else if (err.response.data.list_on_web) {
            message = "List on Web: " + err.response.data.list_on_web;
          } else if (err.response.data.web_display_data) {
            message =
              "Job Kit Display Info: " + err.response.data.web_display_data;
          } else if (err.response.data.jobkitphoto_set) {
            for (const obj of err.response.data.jobkitphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveJobKit({}, jobKitUUID) {
    return ServiceWorkshop.retrieveJobKit(jobKitUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateJobKit({ commit }, { jobKitUUID, formData }) {
    return ServiceWorkshop.updateJobKit(jobKitUUID, formData)
      .then((resp) => {
        commit("UPDATE_JOB_KIT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Kit updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.category) {
            message = "Category: " + err.response.data.category;
          } else if (err.response.data.title) {
            message = "Title: " + err.response.data.title;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.parts) {
            message = "Parts: " + err.response.data.parts;
          } else if (err.response.data.labours) {
            message = "Labours: " + err.response.data.labours;
          } else if (err.response.data.list_on_web) {
            message = "List on Web: " + err.response.data.list_on_web;
          } else if (err.response.data.web_display_data) {
            message =
              "Job Kit Display Info: " + err.response.data.web_display_data;
          } else if (err.response.data.jobkitphoto_set) {
            for (const obj of err.response.data.jobkitphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteJobKit({ commit }, jobKitUUID) {
    return ServiceWorkshop.deleteJobKit(jobKitUUID)
      .then((resp) => {
        commit("DELETE_JOB_KIT", jobKitUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Job Kit deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // job
  getWorkshopJobsList(
    { commit },
    {
      perPage,
      page,
      searchQuery,
      department,
      jobKitCategory,
      jobKits,
      serviceTypes,
      createdFrom,
      createdTo,
      status,
      vehicleUUID,
      universalSearch,
      isHistoricalOpenJobs,
    }
  ) {
    return ServiceWorkshop.getWorkshopJobsList(
      perPage,
      page,
      searchQuery,
      department,
      jobKitCategory,
      jobKits,
      serviceTypes,
      createdFrom,
      createdTo,
      status,
      vehicleUUID,
      isHistoricalOpenJobs
    )
      .then((resp) => {
        if (universalSearch !== true) {
          commit("SET_WORKSHOP_JOBS_LIST", resp.data.results);
          commit("SET_WORKSHOP_JOBS_COUNT", resp.data.count);
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopJobsListWithoutPagination({}, { searchQuery }) {
    return ServiceWorkshop.getWorkshopJobsListWithoutPagination(searchQuery)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopCalendarJobsAndNotes({}, { startAt, endAt }) {
    return ServiceWorkshop.getWorkshopCalendarJobsAndNotes(startAt, endAt)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCalendarNotesList({}, { startAt, endAt }) {
    return ServiceWorkshop.getCalendarNotesList(startAt, endAt)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addCalendarNote({}, formData) {
    return ServiceWorkshop.addCalendarNote(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Calendar note created successfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: err.response.data.message,
        });

        return err.response;
      });
  },

  updateCalendarNote({}, { noteUUID, formData }) {
    return ServiceWorkshop.updateCalendarNote(noteUUID, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Calendar note updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: err.response.data.message,
        });

        return err.response;
      });
  },

  deleteCalendarNote({}, noteUUID) {
    return ServiceWorkshop.deleteCalendarNote(noteUUID)
      .then((resp) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Calendar note deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: err.response.data.message,
        });

        return err.response;
      });
  },

  getCustomerWorkshopJobsList(
    { commit },
    { customerUUID, perPage, page, searchQuery }
  ) {
    return ServiceWorkshop.getCustomerWorkshopJobsList(
      customerUUID,
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_CUSTOMER_WORKSHOP_JOBS_LIST", resp.data.results);
        commit("SET_CUSTOMER_WORKSHOP_JOBS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getMechanicDayJobsListWithoutPagination({}) {
    return ServiceWorkshop.getMechanicDayJobsListWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addWorkshopJob({ commit }, formData) {
    return ServiceWorkshop.addWorkshopJob(formData)
      .then((resp) => {
        commit("ADD_WORKSHOP_JOB", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job created successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.vehicle) {
            message = "Vehicle: " + err.response.data.vehicle;
          } else if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer;
          } else if (err.response.data.title) {
            message = "Job Title: " + err.response.data.title;
          } else if (err.response.data.job_kit) {
            message = "Job Kit: " + err.response.data.job_kit;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.service_type) {
            message = "Service Type: " + err.response.data.service_type;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.start_at) {
            message = "Start At: " + err.response.data.start_at;
          } else if (err.response.data.end_at) {
            message = "End At: " + err.response.data.end_at;
          } else if (err.response.data.mechanic) {
            message = "Mechanic: " + err.response.data.mechanic;
          } else if (err.response.data.mechanic_remarks) {
            message = "Mechanic Remarks: " + err.response.data.mechanic_remarks;
          } else if (err.response.data.kilometers) {
            message = "Kilometers: " + err.response.data.kilometers;
          } else if (err.response.data.jobphoto_set) {
            for (const obj of err.response.data.jobphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  addQuickJob({ commit }, formData) {
    return ServiceWorkshop.addQuickJob(formData)
      .then((resp) => {
        commit("ADD_WORKSHOP_JOB", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Cash Sale created successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.vehicle) {
            message = "Vehicle: " + err.response.data.vehicle;
          } else if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateQuickJob({ commit }, { jobUUID, formData }) {
    return ServiceWorkshop.updateQuickJob(jobUUID, formData)
      .then((resp) => {
        commit("UPDATE_WORKSHOP_JOB", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Cash Sale updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.vehicle) {
            message = "Vehicle: " + err.response.data.vehicle;
          } else if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer;
          } else if (err.response.data.invoice_number) {
            message = "Invoice Number: " + err.response.data.invoice_number;
          } else if (err.response.data.reference) {
            message = "Reference: " + err.response.data.reference;
          } else if (err.response.data.currency) {
            message = "Currency: " + err.response.data.currency;
          } else if (err.response.data.line_amount_type) {
            message = "Line Amount Type: " + err.response.data.line_amount_type;
          } else if (err.response.data.include_discount) {
            message = "Include Discount: " + err.response.data.include_discount;
          } else if (err.response.data.due_date) {
            message = "Due Date: " + err.response.data.due_date;
          } else if (err.response.data.issue_date) {
            message = "Issue Date: " + err.response.data.issue_date;
          } else if (err.response.data.status) {
            message = "Status: " + err.response.data.status;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          } else if (err.response.data.invoicelineitem_set.length > 0) {
            for (const errObj of err.response.data.invoicelineitem_set) {
              if (Object.keys(errObj).length > 0) {
                if (errObj.seq_no) {
                  message = "Seq No.: " + errObj.seq_no;
                } else if (errObj.item_type) {
                  message = "Item: " + errObj.item_type;
                } else if (errObj.product) {
                  message = "Item: " + errObj.product;
                } else if (errObj.general_service) {
                  message = "Item: " + errObj.general_service;
                } else if (errObj.description) {
                  message = "Description: " + errObj.description;
                } else if (errObj.quantity) {
                  message = "Quantity: " + errObj.quantity;
                } else if (errObj.cost_price) {
                  message = "Cost Price: " + errObj.cost_price;
                } else if (errObj.price) {
                  message = "Price: " + errObj.price;
                } else if (errObj.tax_rate) {
                  message = "Tax Rate: " + errObj.tax_rate;
                } else if (errObj.xero_account) {
                  message = "Account: " + errObj.xero_account;
                } else if (errObj.discount_amount) {
                  message = "Discount Amount: " + errObj.discount_amount;
                } else if (errObj.total_amount) {
                  message = "Amount: " + errObj.total_amount;
                } else if (errObj.action_required) {
                  message = errObj.action_required[0];
                }
                break;
              }
            }
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveWorkshopJob({}, jobUUID) {
    return ServiceWorkshop.retrieveWorkshopJob(jobUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveMechanicWorkshopJob({}, jobUUID) {
    return ServiceWorkshop.retrieveMechanicWorkshopJob(jobUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateWorkshopJob({ commit }, { jobUUID, formData, updateType }) {
    return ServiceWorkshop.updateWorkshopJob(jobUUID, formData)
      .then((resp) => {
        commit("UPDATE_WORKSHOP_JOB", resp.data);

        if (updateType == "job") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Job updated successfully",
          });
        } else if (updateType == "mechanic-remarks") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Mechanic Remarks updated successfully",
          });
        } else if (updateType == "mechanic-checklist") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Mechanic Checklist updated successfully",
          });
        } else if (updateType == "photos") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Job Photos updated successfully",
          });
        } else if (updateType == "note") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Job Note updated successfully",
          });
        }

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.vehicle) {
            message = "Vehicle: " + err.response.data.vehicle;
          } else if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer;
          } else if (err.response.data.title) {
            message = "Job Title: " + err.response.data.title;
          } else if (err.response.data.job_kit) {
            message = "Job Kit: " + err.response.data.job_kit;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.service_type) {
            message = "Service Type: " + err.response.data.service_type;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.note) {
            message = "Note: " + err.response.data.note;
          } else if (err.response.data.start_at) {
            message = "Start At: " + err.response.data.start_at;
          } else if (err.response.data.end_at) {
            message = "End At: " + err.response.data.end_at;
          } else if (err.response.data.mechanic) {
            message = "Mechanic: " + err.response.data.mechanic;
          } else if (err.response.data.mechanic_remarks) {
            message = "Mechanic Remarks: " + err.response.data.mechanic_remarks;
          } else if (err.response.data.kilometers) {
            message = "Kilometers: " + err.response.data.kilometers;
          } else if (err.response.data.jobphoto_set) {
            for (const obj of err.response.data.jobphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteWorkshopJob({ commit }, jobUUID) {
    return ServiceWorkshop.deleteWorkshopJob(jobUUID)
      .then((resp) => {
        commit("DELETE_WORKSHOP_JOB", jobUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Job deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  recordWorkshopJobTime({}, { jobUUID, formData }) {
    return ServiceWorkshop.recordWorkshopJobTime(jobUUID, formData)
      .then((resp) => {
        if (formData.last_action == "start") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Job time record started",
          });
        } else if (formData.last_action == "pause") {
          Notification.warning({
            position: "bottom-right",
            duration: 2000,
            title: "Warning",
            message: "Job time record paused",
          });
        } else if (formData.last_action == "end") {
          Notification.success({
            position: "bottom-right",
            duration: 2000,
            title: "Success",
            message: "Job time record finished",
          });
        }

        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  markWorkshopJobFinished({}, jobUUID) {
    return ServiceWorkshop.markWorkshopJobFinished(jobUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job marked as finished successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  markWorkshopJobUnfinished({}, jobUUID) {
    return ServiceWorkshop.markWorkshopJobUnfinished(jobUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job marked as unfinished successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  markWorkshopJobCompleted({}, jobUUID) {
    return ServiceWorkshop.markWorkshopJobCompleted(jobUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job marked as completed successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  markQuickJobCompleted({}, jobUUID) {
    return ServiceWorkshop.markQuickJobCompleted(jobUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job marked as completed successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  generateWorkshopJobServiceReportPDF({ commit }, { jobUUID, templateName }) {
    return ServiceWorkshop.generateWorkshopJobServiceReportPDF(
      jobUUID,
      templateName
    )
      .then((resp) => {
        commit("SAVE_SERVICE_REPORT_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating service report pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkWorkshopJobServiceReportPDFGenerationTaskStatus(
    { commit },
    { jobUUID, formData }
  ) {
    return ServiceWorkshop.checkWorkshopJobServiceReportPDFGenerationTaskStatus(
      jobUUID,
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_SERVICE_REPORT_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  shareWorkshopJobServiceReportWithCustomer({}, { jobUUID, formData }) {
    return ServiceWorkshop.shareWorkshopJobServiceReportWithCustomer(
      jobUUID,
      formData
    )
      .then((resp) => {
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending service report URL to customer!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  workshopJobServiceReportCustomerView({}, jobUUID) {
    return ServiceWorkshop.workshopJobServiceReportCustomerView(jobUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopJobFormData({}) {
    return ServiceWorkshop.getWorkshopJobFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  generateWorkshopJobCardPDF({ commit }, jobUUID) {
    return ServiceWorkshop.generateWorkshopJobCardPDF(jobUUID)
      .then((resp) => {
        commit("SAVE_WORKSHOP_JOB_CARD_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating job card pdf. Please wait!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkWorkshopJobCardPDFGenerationTaskStatus(
    { commit },
    { jobUUID, formData }
  ) {
    return ServiceWorkshop.checkWorkshopJobCardPDFGenerationTaskStatus(
      jobUUID,
      formData
    )
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_WORKSHOP_JOB_CARD_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  sendWorkshopJobPhotosDirectUploadUrl({}, jobUUID) {
    return ServiceWorkshop.sendWorkshopJobPhotosDirectUploadUrl(jobUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "SMS sent with Job Photos upload URL successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getWorkshopJobPhotosInQuickUpload({}, base64Token) {
    return ServiceWorkshop.getWorkshopJobPhotosInQuickUpload(base64Token)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.jobphoto_set) {
            for (const obj of err.response.data.jobphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  uploadWorkshopJobPhotosQuick({}, { base64Token, formData }) {
    return ServiceWorkshop.uploadWorkshopJobPhotosQuick(base64Token, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Photos uploaded successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.jobphoto_set) {
            for (const obj of err.response.data.jobphoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateWofJobData({}, { jobUUID, formData }) {
    return ServiceWorkshop.updateWofJobData(jobUUID, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Vehicle WOF data updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.is_wof_passed) {
            message = "WOF: " + err.response.data.is_wof_passed;
          } else if (err.response.data.wof_failure_details) {
            message =
              "WOF Failure Details: " + err.response.data.wof_failure_details;
          } else if (err.response.data.conflict_of_interest) {
            message =
              "Conflict of Interest: " + err.response.data.conflict_of_interest;
          } else if (err.response.data.check_sheet_no) {
            message = "Check Sheet No.: " + err.response.data.check_sheet_no;
          } else if (err.response.data.conflict_details) {
            message = "Conflict Details: " + err.response.data.conflict_details;
          } else if (err.response.data.inspector) {
            message = "Inspector: " + err.response.data.inspector;
          } else if (err.response.data.free_recheck_date) {
            message =
              "Last Day For Free Re-check: " +
              err.response.data.free_recheck_date;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_error[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  generateWofConflictFormPdf({ commit }, jobUUID) {
    return ServiceWorkshop.generateWofConflictFormPdf(jobUUID)
      .then((resp) => {
        commit("SAVE_WOF_CONFLICT_FORM_GENERATE_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Generating Conflict of Interest form pdf!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkWofConflictFormPdfTaskStatus({ commit }, { jobUUID, formData }) {
    return ServiceWorkshop.checkWofConflictFormPdfTaskStatus(jobUUID, formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_WOF_CONFLICT_FORM_GENERATE_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // job document
  getWorkshopJobDocumentsList({ commit }, { jobUUID, perPage, page }) {
    return ServiceWorkshop.getWorkshopJobDocumentsList(jobUUID, perPage, page)
      .then((resp) => {
        commit("SET_WORKSHOP_JOB_DOCUMENTS_LIST", resp.data.results);
        commit("SET_WORKSHOP_JOB_DOCUMENTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addWorkshopJobDocument({ commit }, { jobUUID, formData }) {
    return ServiceWorkshop.addWorkshopJobDocument(jobUUID, formData)
      .then((resp) => {
        commit("ADD_WORKSHOP_JOB_DOCUMENT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Document uploaded successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.file) {
            message = err.response.data.file;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateWorkshopJobDocument(
    { commit },
    { jobUUID, jobDocumentUUID, formData }
  ) {
    return ServiceWorkshop.updateWorkshopJobDocument(
      jobUUID,
      jobDocumentUUID,
      formData
    )
      .then((resp) => {
        commit("UPDATE_WORKSHOP_JOB_DOCUMENT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Document updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.file) {
            message = err.response.data.file;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteWorkshopJobDocument({ commit }, { jobUUID, jobDocumentUUID }) {
    return ServiceWorkshop.deleteWorkshopJobDocument(jobUUID, jobDocumentUUID)
      .then((resp) => {
        commit("DELETE_WORKSHOP_JOB_DOCUMENT", jobDocumentUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Job Document deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // general service
  getWorkshopGeneralServicesList({ commit }, { perPage, page, searchQuery }) {
    return ServiceWorkshop.getWorkshopGeneralServicesList(
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_WORKSHOP_GENERAL_SERVICES_LIST", resp.data.results);
        commit("SET_WORKSHOP_GENERAL_SERVICES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopGeneralServicesListWithoutPagination({}, { searchQuery }) {
    return ServiceWorkshop.getWorkshopGeneralServicesListWithoutPagination(
      searchQuery
    )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopGeneralServicesFirst20List({}) {
    return ServiceWorkshop.getWorkshopGeneralServicesFirst20List()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addWorkshopGeneralService({ commit }, formData) {
    return ServiceWorkshop.addWorkshopGeneralService(formData)
      .then((resp) => {
        commit("ADD_WORKSHOP_GENERAL_SERVICE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "General Service created successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.cost_price) {
            message = "Cost Price: " + err.response.data.cost_price;
          } else if (err.response.data.retail_price) {
            message = "Retail Rate: " + err.response.data.retail_price;
          } else if (err.response.data.tax_rate) {
            message = "Tax Rate: " + err.response.data.tax_rate;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.list_on_web) {
            message = "List On Web: " + err.response.data.list_on_web;
          } else if (err.response.data.web_display_data) {
            message = "Web Display Data: " + err.response.data.web_display_data;
          } else if (err.response.data.generalservicephoto_set) {
            for (const obj of err.response.data.generalservicephoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveWorkshopGeneralService({}, serviceUUID) {
    return ServiceWorkshop.retrieveWorkshopGeneralService(serviceUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateWorkshopGeneralService({ commit }, { serviceUUID, formData }) {
    return ServiceWorkshop.updateWorkshopGeneralService(serviceUUID, formData)
      .then((resp) => {
        commit("UPDATE_WORKSHOP_GENERAL_SERVICE", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "General Service updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.cost_price) {
            message = "Cost Price: " + err.response.data.cost_price;
          } else if (err.response.data.retail_price) {
            message = "Retail Rate: " + err.response.data.retail_price;
          } else if (err.response.data.tax_rate) {
            message = "Tax Rate: " + err.response.data.tax_rate;
          } else if (err.response.data.department) {
            message = "Department: " + err.response.data.department;
          } else if (err.response.data.list_on_web) {
            message = "List On Web: " + err.response.data.list_on_web;
          } else if (err.response.data.web_display_data) {
            message = "Web Display Data: " + err.response.data.web_display_data;
          } else if (err.response.data.generalservicephoto_set) {
            for (const obj of err.response.data.generalservicephoto_set) {
              for (const key of Object.keys(obj)) {
                message = obj[key][0];
                break;
              }
              if (message) {
                break;
              }
            }
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteWorkshopGeneralService({ commit }, serviceUUID) {
    return ServiceWorkshop.deleteWorkshopGeneralService(serviceUUID)
      .then((resp) => {
        commit("DELETE_WORKSHOP_GENERAL_SERVICE", serviceUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "General Service deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getWorkshopGeneralServiceFormData({}) {
    return ServiceWorkshop.getWorkshopGeneralServiceFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  // recommendation types
  getRecommendationCategories({ commit }, { perPage, page, searchQuery }) {
    return ServiceWorkshop.getRecommendationCategories(
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_RECOMMENDATION_CATEGORY_LIST", resp.data.results);
        commit("SET_RECOMMENDATION_CATEGORY_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getRecommendationCategoriesWithoutPagination({}) {
    return ServiceWorkshop.getRecommendationCategoriesWithoutPagination()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addRecommendationCategory({ commit }, formData) {
    return ServiceWorkshop.addRecommendationCategory(formData)
      .then((resp) => {
        commit("ADD_RECOMMENDATION_CATEGORY", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Recommendation Category added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateRecommendationCategory({ commit }, { objId, formData }) {
    return ServiceWorkshop.updateRecommendationCategory(objId, formData)
      .then((resp) => {
        commit("UPDATE_RECOMMENDATION_CATEGORY", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Recommendation Category updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteRecommendationCategory({ commit }, objId) {
    return ServiceWorkshop.deleteRecommendationCategory(objId)
      .then((resp) => {
        commit("DELETE_RECOMMENDATION_CATEGORY", objId);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Recommendation Category deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getRecommendationFormData({}) {
    return ServiceWorkshop.getRecommendationFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addRecommendation({ commit }, formData) {
    return ServiceWorkshop.addRecommendation(formData)
      .then((resp) => {
        commit("ADD_RECOMMENDATION", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Recommendation added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.title) {
            message = "Title: " + err.response.data.title;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getRecommendations({ commit }, { perPage, page, searchQuery }) {
    return ServiceWorkshop.getRecommendations(perPage, page, searchQuery)
      .then((resp) => {
        commit("SET_RECOMMENDATION_LIST", resp.data.results);
        commit("SET_RECOMMENDATION_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getRecommendationsWithoutPagination({}, { searchQuery }) {
    return ServiceWorkshop.getRecommendationsWithoutPagination(searchQuery)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveRecommendation({}, uuid) {
    return ServiceWorkshop.retrieveRecommendation(uuid)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateRecommendation({ commit }, { uuid, formData }) {
    return ServiceWorkshop.updateRecommendation(uuid, formData)
      .then((resp) => {
        commit("UPDATE_RECOMMENDATION", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Recommendation updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.title) {
            message = "Title: " + err.response.data.title;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteRecommendation({ commit }, uuid) {
    return ServiceWorkshop.deleteRecommendation(uuid)
      .then((resp) => {
        commit("DELETE_RECOMMENDATION", uuid);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Recommendation deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  createJobRecommendation({}, formData) {
    return ServiceWorkshop.createJobRecommendation(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Recommendation added successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateJobRecommendation({}, { uuid, formData }) {
    return ServiceWorkshop.updateJobRecommendation(uuid, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Recommendation updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteJobRecommendation({}, uuid) {
    return ServiceWorkshop.deleteJobRecommendation(uuid)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Job Recommendation deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
